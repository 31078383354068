import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import Vue from 'vue';
import { requiredRule } from '@/vue-app/utils/form-rules';

// Application
import { SearchTemplateQuestionnairesQuery }
  from '@/modules/my-investment/catalogs/allianz/template-questionnaire/application/queries/';
import GetInvestmentTypeQuery
  from '@/modules/my-investment/allianz/investment-type/application/queries/get-investment-type-query';
import {
  SearchOnBoardingStepsQuery,
} from '@/modules/my-investment/on-boarding-steps/application/queries';
import {
  SearchInvestmentProvidersQuery,
} from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';

// Domain
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import {
  FilterDto,
} from '@/modules/my-investment/catalogs/allianz/template-questionnaire/domain/dtos/filter-dto';
import {
  OptionEntity,
} from '@/modules/my-investment/catalogs/allianz/option/domain/entities/option-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsProfilingResultsViewModel {
  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_QUESTIONNAIRE_TEMPLATE_QUERY)
  private readonly search_template_questionnaires_query!: SearchTemplateQuestionnairesQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly search_on_boarding_steps_query!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly search_investment_providers_query!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly create_on_boarding_step_command!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly update_on_boarding_step_command!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.GET_ALLIANZ_INVESTMENT_TYPE_QUERY)
  private readonly get_investment_type_query!: GetInvestmentTypeQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.contract-savings.profiling.results';

  investment_provider_name = 'allianz';

  step_name = 'select_investor_profile';

  exists_step = false;

  investment_provider_id = '';

  is_loading = true;

  readonly filter_template: FilterDto = {
    tipoCuestionario: 'PERFILAMIENTO_INVERSION',
    versionCuestionario: '1.0.0',
  };

  small_screen = false;

  valid_form = false;

  resulted_profile = '';

  expected_profile_help = '';

  expected_profile = '';

  previous_step_name = 'investor_profile';

  on_boarding_step: OnBoardingStepEntity = {
    id: '',
    current_step: '',
    investment_provider_id: '',
    payload: {
      investor_type_expected: {
        llave: '',
        valor: '',
        descripcion: '',
      },
      investor_type_calculated: {
        llave: '',
        valor: '',
        descripcion: '',
      },
      accpet_investor_objectives: false,
      investor_type_expected_profile: '',
      investor_type_calculated_profile: '',
    },
  };

  private previous_on_boarding_step: OnBoardingStepEntity | null = null;

  conservative_items: Array<OptionEntity> = [];

  moderate_items: Array<OptionEntity> = [];

  aggressive_items: Array<OptionEntity> = [];

  investor_types: Array<string> = ['Conservador', 'Moderado', 'Agresivo'];

  inputs_rules = {
    investor_type_expected: [requiredRule],
    accept_investor_objectives: [requiredRule],
  }

  enable_edit_result = false;

  public constructor(view: Vue) {
    this.view = view;
  }

  get can_continue() {
    return this.valid_form && !this.is_loading && !this.enable_edit_result;
  }

  get can_save_selection() {
    return this.investor_types.indexOf(
      this.on_boarding_step.payload.investor_type_expected_profile,
    ) !== -1;
  }

  get investment_providers() {
    return this.search_investment_providers_query.execute();
  }

  get steps() {
    return this.search_on_boarding_steps_query.execute(this.investment_provider_id);
  }

  get template_questionnaires() {
    return this.search_template_questionnaires_query.execute(this.filter_template);
  }

  get investor_type_changed() {
    return this.expected_profile !== '' && this.resulted_profile !== this.expected_profile;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  activeEditResult = () => {
    this.enable_edit_result = !this.enable_edit_result;
  }

  onInvestorTypeChanged = (investor_type: string) => {
    this.investorTypeExpected(investor_type);
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.resulted_profile = search_step.payload.investor_type_calculated_profile;
      this.investorTypeExpected(search_step.payload.investor_type_expected_profile);
      this.searchInvestorProfileStep(on_boarding_steps);
      await this.getInvestorTypeCalculated();
      this.on_boarding_step = { ...search_step };
    } else {
      this.searchInvestorProfileStep(on_boarding_steps);
      await this.getInvestorTypeCalculated();
    }
  };

  searchInvestorProfileStep = (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_previous_step = on_boarding_steps.find(
      (step) => step.current_step === this.previous_step_name,
    );
    if (search_previous_step) {
      this.previous_on_boarding_step = search_previous_step;
    }
  }

  getInvestorTypeCalculated = async () => {
    try {
      const points = this.previous_on_boarding_step?.payload?.investor_calculated_points;
      if (points) {
        const profile = await this.get_investment_type_query.execute(points);
        this.investorTypeCalculated(profile.investment_type);
      }
    } catch {
      this.message_notifier.showErrorNotification(i18n.tc(`${this.i18n_namespace}.error_on_get_investor_type`));
    }
  }

  fillInvestorTypeOptions = async () => {
    const template_questionnaires = await this.template_questionnaires;
    const { preguntas: investor_type } = template_questionnaires[0].secciones[3];
    this.conservative_items = investor_type[0].opciones;
    this.moderate_items = investor_type[1].opciones;
    this.aggressive_items = investor_type[2].opciones;
  }

  investorTypeExpected(investor_type: string) {
    this.expected_profile = investor_type;
    this.on_boarding_step.payload.investor_type_expected_profile = investor_type;
    if (investor_type === 'Conservador') {
      this.on_boarding_step.payload.investor_type_expected = { ...this.conservative_items[0] };
      this.expected_profile_help = this.translate('profile.options.conservative.description');
    }
    if (investor_type === 'Moderado') {
      this.on_boarding_step.payload.investor_type_expected = { ...this.moderate_items[0] };
      this.expected_profile_help = this.translate('profile.options.moderated.description');
    }
    if (investor_type === 'Agresivo') {
      this.on_boarding_step.payload.investor_type_expected = { ...this.aggressive_items[0] };
      this.expected_profile_help = this.translate('profile.options.aggressive.description');
    }
  }

  investorTypeCalculated(investor_type: string) {
    this.resulted_profile = investor_type;
    this.on_boarding_step.payload.investor_type_calculated_profile = investor_type;
    if (investor_type === 'Conservador') {
      this.on_boarding_step.payload.investor_type_calculated = { ...this.conservative_items[0] };
    }
    if (investor_type === 'Moderado') {
      this.on_boarding_step.payload.investor_type_calculated = { ...this.moderate_items[0] };
    }
    if (investor_type === 'Agresivo') {
      this.on_boarding_step.payload.investor_type_calculated = { ...this.aggressive_items[0] };
    }
    if (!this.on_boarding_step.payload.investor_type_expected_profile) {
      this.investorTypeExpected(investor_type);
    }
  }

  saveInvestorTypeExpected = () => {
    const investor_type_expected = this.on_boarding_step.payload.investor_type_expected_profile;
    this.investorTypeExpected(investor_type_expected);
    this.enable_edit_result = false;
    this.on_boarding_step.payload.accpet_investor_objectives = false;
  }

  saveStep = async () => {
    this.is_loading = true;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.update_on_boarding_step_command.execute(this.on_boarding_step);
      } else {
        await this.create_on_boarding_step_command.execute(this.on_boarding_step);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('components.contract-savings.error_on_save_step'));
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    const save_step = await this.saveStep();
    if (save_step) {
      this.view.$emit('nextStep');
    }
  }

  initialize = async (screen_size: string) => {
    this.small_screen = screen_size === 'small';
    await this.fillInvestorTypeOptions();
    const investment_providers = await this.investment_providers;
    this.setInvestmentProviderId(investment_providers);
    const steps = await this.steps;
    await this.verifyStep(steps);
    this.is_loading = false;
  }
}
